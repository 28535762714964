.logo-tape {
    overflow: hidden;
    width: 100%;
    height: 15vh;
    background: rgb(216, 82, 82);
    padding: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.logo-tape-content {
    display: flex;
    animation: moveTape 30s linear infinite;
    width: max-content;
    padding: 2rem 0;
}

.logo-tape-content img {
    height: 50px;
    margin: 0 20px;
    transition: transform 0.3s;
}

.logo-tape-content img:hover {
    transform: scale(1.1);
}

@keyframes moveTape {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(calc(-100% + 100vw));
    }
}

@media (max-width: 600px) {
    .logo-tape-content {
        animation: moveTape 30s linear infinite;
    }
}

@media (min-width: 700px) and (max-width: 1368px) {
    .logo-tape-content {
        padding-top: 4rem;
    }
}
