.footer {
  background-color: #222121;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.container_footer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo .logo {
  height: 140px;
  width: 170px;
  margin-top: -60px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links li {
  margin: 0;
}

.footer-links a {
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #db2b39;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-social {
  margin-top: 20px;
}

.footer-social .social-link {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s;
}

.footer-social .social-link:hover {
  color: #007BFF;
}

.container_footer_div {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;
}

@media (max-width: 600px) {
  .footer-links ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer-links a {
    font-size: 0.9em;
  }

  .footer-contact p {
    font-size: 0.9em;
  }

  .footer-social .social-link {
    font-size: 1.2em;
  }
}
