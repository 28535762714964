.container_Design {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 20px;
  box-sizing: border-box;
}

.hexagon_Design {
  width: 450px;
  height: 450px;
  background: #c0645a;
  position: relative;
  clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.9s ease-out;
}

.hexagon_Design:hover {
  background-color: #f08074;
}

.hexagon_Design .content {
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.hexagon_Design h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.hexagon_Design ul {
  list-style: none;
  padding: 0;
}

.hexagon_Design li {
  margin: 5px 0;
}

@media (max-width: 1368px) {
  .container_Design {
    height: auto;
    flex-direction: column;
  }

  .hexagon_Design {
    width: 100%;
    height: auto;
    margin: 10px 0;
    clip-path: none;
    border-radius: 10px;
  }

  .hexagon_Design .content {
    padding: 10px;
  }

  .hexagon_Design h2 {
    font-size: 1.2em;
  }

  .hexagon_Design ul {
    padding: 0 10px;
  }

  .hexagon_Design li {
    margin: 3px 0;
  }
}


@media (min-width: 1200px) and (max-width: 1370px) {
  .hexagon_Design {
    width: 100%;
    height: auto;
    margin: 20px 0;
    clip-path: none;
    border-radius: 10px;
  }

  .hexagon_Design .content {
    padding: 20px;
  }

  .hexagon_Design h2 {
    font-size: 2rem;
  }

  .hexagon_Design ul {
    padding: 0 30px;
  }

  .hexagon_Design li {
    font-size: 1.4rem;

    margin: 5px 0;
  }
}

 

