#why-choose-us {
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: right;
    direction: rtl;
  }
  
  #why-choose-us .container {
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }
  
  #why-choose-us h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  #why-choose-us .reasons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  #why-choose-us .reason {
    flex: 1;
    min-width: 300px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: 0.8s ease-in-out;
  }
  
  #why-choose-us .reason h3 {
    font-size: 1.3em;
    margin-bottom: 10px;
    color: #007BFF;
  }
  
  #why-choose-us .reason p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #666;
  }
  
  #why-choose-us .reason p strong {
    color: #333;
  }
  
  #why-choose-us .reason:hover {
    background-color: #2d92fd;
    color: #fff;
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  #why-choose-us .reason:hover h3 {
    color: #fff;
  }
  
  #why-choose-us .reason:hover p {
    color: #fff;
  }
  
  #why-choose-us .reason:hover p strong {
    color: #fff;
  }
  
  @media (max-width: 600px) {
    #why-choose-us .container {
      padding: 0 10px;
    }
  
    #why-choose-us h2 {
      font-size: 2em;
    }
  
    #why-choose-us .reason h3 {
      font-size: 1.2em;
    }
  
    #why-choose-us .reason p {
      font-size: 0.9em;
    }
  }
  