.Service_gap{
    text-align: center;
}


  
.ServiceListConatainer {
    background-image: url('/public/image/photo-1507238691740-187a5b1d37b8.jpg');
    height: 80vh;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .ServiceListConatainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1;
  }
  
  .ServiceListConatainer > * {
    position: relative;
    z-index: 2;
  }


 /* Styles for larger screens (laptops and desktops) */
.contact-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-decoration: none;
  margin-top: 50px;
}

.contact-button a {
  color: #fff;
  text-decoration: none;
}

.contact-button:hover {
  background-color: #0056b3;
}



.ServiceList_p {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10rem;
  padding-right: 2rem;
}

.ServiceList_p p {
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}

/* Media queries for smaller screens (phones) */
@media (max-width: 600px) {
  .contact-button {
    padding: 15px 25px;
    font-size: 1rem;
    margin-top: 30px;
  }


  .ServiceList_p {
    padding-top: 50px;
    align-items: center;
    padding-left: 20px;
  }

  .ServiceList_p p {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
}

  
  @media (min-width: 1368px) {
    .servislist {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      background-size: cover;
      background-position: center;
      background-color: rgb(219, 207, 183);
    }
  
    .ServiceList_p {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 80px;
    }
  
    .ServiceList_p p {
      font-size: 30px;
      line-height: 30px;
      color: #fff;
      margin-top: 20px;
    }
  
    .Service_gap {
      background-color: rgb(219, 207, 183);
      padding-top: 80px;
      padding-bottom: 30px;
      text-align: center;

    }
  }
  

  @media  (min-width: 700px) and (max-width: 1368px){
    .servislist {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      background-size: cover;
      background-position: center;
      background-color: rgb(219, 207, 183);
    }
  
  
    .Service_gap {
      background-color: rgb(219, 207, 183);
      padding-top: 60px;
      text-align: center;

    }
    .our_servies{
      background-color: rgb(219, 207, 183);
    }
  }


  @media (min-width: 1200px) and (max-width: 1370px) {
    .servislist {
      display: block;
      
      background-size: cover;
      background-position: center;
      background-color: #fff;
    }
    .Service_gap {
      background-color: #fff;
      padding-top: 60px;
      text-align: center;
    }
    .our_servies{
      background-color:#fff;
    }

 

  }
  