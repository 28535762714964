.HomeFiristSection{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: self-end;
  color:#fff;
  padding-right: 2rem;
  padding-top: 1rem;
}

.HomeFiristSection h1 {
  color: rgba(247, 57, 57, 0.959);
}


@media only screen and (max-width: 1024px) {
  .HomeFiristSection{

    padding-right: 0rem;
    margin: 2rem;
  }
}