/* Header.css */
:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 0rem;
  background-color: transparent;
  color: white;
}

nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}

nav ul li a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  display: none;
}

.logo-container {
  width: 20%;
  padding-top: 1rem;
}

.logo-container img {
  width: 14rem;
}

.contactus_Header{
  background-color: black;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  header ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
  }
  .logo-container {
    width: 50%;
  }
  .logo-container img {
    width: 10rem;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: black;
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav ul li {
    font-size: 1.5rem;
  }
}